"use client";
import React from "react";
import stl from "../styles/advantagecms.module.scss";
import classnames from "classnames";
import { useCommonContext } from "@/src/provider/Common/context";
import { openChat } from "@/lib/chatOperation";
import { EIconType } from "@/public/static/interface";
interface IProps {
    index: number;
    iconList: EIconType[];
}
const AdvantageItemClient: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    const { host } = useCommonContext();

    return (
        <div
            className={classnames(stl["advantage-item"], "advantage-item")}
            // icon-chat时点击可弹出open-chat弹窗
            onClick={() =>
                (props.iconList[props.index] as string) === "icon--chat" &&
                openChat(host)
            }
        >
            {props.children}
        </div>
    );
};

export default AdvantageItemClient;
