"use client";
import getInnerAddsSchemes, { IInnerAddsProps } from "@/src/utils/innerAdds";
import { useEffect, useState } from "react";
import bindFunctionToWindow from "@/lib/ads/severs";
import InnerAddsContentAlert from "./InnerAddsContentAlert";
import InnerAddsContentEmbed from "./InnerAddsContentEmbed";
import { useUserInfoContext } from "@/src/provider";
import { isLogin } from "@/src/models";

// 获取站内方案的数据
export default function InnerAddsComponent({
    searchParams,
    page_url,
}: Readonly<IInnerAddsProps>) {
    const [innerAddsData, setInnerAddsData] = useState<any>();
    const [isLoading, userInfo, getUserInfo] = useUserInfoContext((state) => [
        state.isLoading,
        state.data,
        state.getUserInfo,
    ]);
    useEffect(() => {
        bindFunctionToWindow();
        // 判断已登录用户信息请求过没有
        if (userInfo.customers_id || !isLogin()) {
            const innerAddParams = {
                customerId: userInfo?.customers_id,
                page_url,
                searchParams,
            };
            getInnerAddsSchemes(innerAddParams).then((data) => {
                setInnerAddsData(data);
            });
        } else {
            getUserInfo();
        }
    }, [page_url, searchParams, userInfo, isLoading]);
    return (
        innerAddsData && (
            <>
                {innerAddsData?.alertData && (
                    <InnerAddsContentAlert
                        alertData={innerAddsData?.alertData}
                    ></InnerAddsContentAlert>
                )}
                {innerAddsData?.embedData && (
                    <InnerAddsContentEmbed
                        embedData={innerAddsData?.embedData}
                    ></InnerAddsContentEmbed>
                )}
            </>
        )
    );
}
