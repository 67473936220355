import React, { useMemo } from "react";
import classnames from "classnames";
import styles from "./skeleton.module.scss";
import { IStyleProps } from "@/src/app/interface";
import FmSkeleton from "@/ui-component/basic/FmSkeleton";

interface IProps extends IStyleProps {
    num?: number; // 默认4个
}

// 颜色选择模块共用骨架屏
const ColorSelectSkeleton: React.FC<IProps> = (props) => {
    const { num = 4 } = props;
    const colorItem = useMemo(() => {
        return Array(num)
            .fill("")
            .map((item) => {
                return (
                    <FmSkeleton
                        key={Math.random()}
                        variant="circular"
                        className={classnames(styles["color-select-skeleton"])}
                    />
                );
            });
    }, [num]);
    return (
        <div
            className={classnames(
                styles["color-select"],
                "color-select",
                props.className,
            )}
            style={props.style}
        >
            {colorItem}
        </div>
    );
};

export { ColorSelectSkeleton };
