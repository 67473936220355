"use client";
import { useEffect } from "react";
import { showSchemes } from "@/src/utils/innerAdds/handleSchemes";

interface InnerAddsContentProps {
    alertData: any;
}

// 渲染，执行站内方案数据
export default function InnerAddsContentAlert(
    props: Readonly<InnerAddsContentProps>,
) {
    const { alertData } = props;
    // 弹出框
    useEffect(() => {
        if ("requestIdleCallback" in window) {
            const alertHandle = window.requestIdleCallback(() => {
                if (alertData && window.$) {
                    window.jQuery = window.$;
                    showSchemes(alertData);
                }
            });

            return () => {
                window.cancelIdleCallback(alertHandle);
            };
        } else {
            setTimeout(() => {
                if (alertData && window.$) {
                    window.jQuery = window.$;
                    showSchemes(alertData);
                }
            }, 1);
        }
    }, [alertData]);

    return <></>;
}
