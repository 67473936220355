// 站内方案类型推导，暂时放在这里
import { postSchemeLog } from "@/src/models/adScheme";
import Display, { UnicodeTransverter } from "@/lib/ads/display";
import { embedInitData, embedPosition } from "@/config/embedPosition";

export type EmbedDataProps = typeof embedInitData;

export type Display = {
    id: number;
    created_at: string;
    updated_at: string;
    position: string;
    way: number;
    name: string;
    size: any;
    float: number;
    is_close: number;
    is_random_close: number;
    page_way: number;
    page_way_time: number;
    site_id: number;
    is_new_customer?: number;
};

export type Scheme = {
    id: number;
    content: string;
    created_at: string;
    updated_at: string;
    is_send_email: number;
    title: string;
    email_tpl: any;
    coupon_code: string;
    email_url: string;
    remind_expire_days: number;
    fixed_coupon: number;
    site_id: number;
};

export type GroupScheme = {
    group_scheme_id: number;
    scheme: Scheme;
    display: Display;
    html?: string;
    id?: string;
    url?: string;
    position: string;
};

export interface MappedSchemes {
    // 嵌入
    embedArr: GroupScheme[];
    // 弹出
    alertArr: GroupScheme[];
    hasEmbed: boolean;
    hasAlert: boolean;
}

interface EmbedDisplayObj {
    [position: string]: {
        html: string | string[];
        id: string;
    };
}

// 分类方案函数
export function mapSchemes(schemes: GroupScheme[]): MappedSchemes {
    const embedArr: GroupScheme[] = [];
    const alertArr: GroupScheme[] = [];

    for (const scheme of schemes) {
        if (scheme.display && scheme.display.way === 1) {
            embedArr.push(scheme);
        } else {
            alertArr.push(scheme);
        }
    }

    return {
        embedArr,
        alertArr,
        hasEmbed: embedArr.length > 0,
        hasAlert: alertArr.length > 0,
    };
}

export function mapSchemesToEmbedDisplayObj(
    embedArr: GroupScheme[],
): GroupScheme[] {
    return embedArr.map((item) => {
        const display = mapSchemeToDisplay(item);
        const unicodet = UnicodeTransverter(display.url);
        item.position = display.position;
        item.html = mapObjToContent(display);
        item.url = display.url;
        item.id = `UP${unicodet}`;

        return item;
    });
}

function mapObjToContent(obj: DisplayInfo): string {
    const unicodet = UnicodeTransverter(obj.url);
    const objClone = { ...obj, html: "" };
    if (objClone.popup_data_save?.scheme) {
        objClone.popup_data_save.scheme.content = "";
    }

    const group_scheme_id = obj.popup_data_save?.group_scheme_id;
    const scheme_id = obj.popup_data_save?.scheme?.id;

    const style = `<style>
    #UP${unicodet}{clear: both;position:relative;height:0;width:0;overflow: hidden;opacity: 0;transition:all 0.5s linear}
    #UP${unicodet}.hover{height:auto;width:auto;opacity: 1;}
    #UP${unicodet} .UPCloseImplant{display: block;top:0;line-height: 25px;right:5px;height:25px;position:absolute;cursor:pointer;z-index: 2;font-size: 25px;text-align: center;}
  </style>`;

    return `
    <div id="UP${unicodet}" class="hover">
      ${style}
      ${
          obj.condition.isclose
              ? '<div><div class="UPCloseImplant onCloseCustom"><span style="font-size:12px;">Close </span>&times;</div></div>'
              : ""
      }
      <div class="UP_config_html" style="text-align: left;overflow: hidden;" data-group_scheme_id=${group_scheme_id} data-scheme_id=${scheme_id}>
        <script>$('#UP${unicodet}')[0].upalert = {}; $('#UP${unicodet}')[0].upalert.config = ${JSON.stringify(
            objClone,
        )}</script>
        ${obj.html}
      </div>
    </div>
  `;
}

const importantPages = ["index.php?main_page=checkout_success"];

type Condition = Record<string, any>;

interface DisplayInfo {
    type: string;
    html: string;
    url: string;
    significance: boolean;
    condition: Condition;
    popup_data_save: GroupScheme;
    position: string;
    onsuccessup: (data: any) => void;
}

export function mapSchemeToDisplay(data: GroupScheme): DisplayInfo {
    const { display, scheme } = data;
    const {
        page_way,
        page_way_time,
        is_random_close,
        is_new_customer,
        is_close,
        way,
        id,
        position,
    } = display;

    const type = displaylist.way[way];
    const html = scheme.content;
    const url = "UP_" + "win=" + scheme.id + "&content=" + id;
    const condition: any = {};
    page_way && (condition[displaylist.page_way[page_way]] = page_way_time);
    is_random_close &&
        (condition.close = displaylist.is_random_close[is_random_close]);
    is_new_customer &&
        (condition.user = displaylist.is_new_customer[is_new_customer]);
    is_close && (condition.isclose = displaylist.is_close[is_close]);
    condition.Cookie =
        condition.everyday || condition.user
            ? [
                  "UP$" +
                      "win_" +
                      data.scheme.id +
                      "&content_" +
                      data.display.id,
                  condition.everyday ? 1 / condition.everyday : 1800,
              ]
            : false;

    let isMaskClose = false;
    try {
        const url = window?.location.href;
        isMaskClose = importantPages.some((item) => url.includes(item));
    } catch (e) {}

    return {
        type,
        html,
        url,
        significance: isMaskClose,
        condition,
        popup_data_save: data,
        position,
        onsuccessup: function (data: any) {
            postSchemeLog({
                transform_group_scheme_id:
                    data.config.popup_data_save.group_scheme_id,
                transform_scheme_id: data.config.popup_data_save.scheme.id,
                transform_display_id: data.config.popup_data_save.display.id,
            });
        },
    };
}

export const displaylist = {
    way: ["alert", "inlay", "alert", "swiper"],
    float: ["center", "left", "center", "right"],
    is_close: [false, true, false],
    is_random_close: [false, true, false],
    page_way: ["false", "statics", "delay"], //默认
    page_way_time: 0,
    is_new_customer: [false, true, false],
};

export function showSchemes(schemes: GroupScheme[]) {
    for (const data of schemes) {
        const { display } = data;
        if (display?.page_way) {
            const obj = mapSchemeToDisplay(data); // Assuming mapSchemeToDisplay function is available
            Display(obj);
        }
    }
}

export function mapEmbedArrToEmbedObj(embedArr: GroupScheme[]): EmbedDataProps {
    const obj: any = {};

    for (const i of embedArr) {
        for (const j in embedPosition) {
            // @ts-ignore
            if (i.position === embedPosition[j]) {
                obj[j] = obj[j] || { html: "", id: "" };
                obj[j].html = obj[j].html ? [obj[j].html, i.html] : i.html;
                obj[j].id = i.id;
            }
        }
    }

    return obj;
}
