"use client";
import { getGlobal } from "@/src/utils";
import classnames from "classnames";
import React, {
    CSSProperties,
    useCallback,
    useEffect,
    useRef,
    useState,
    useMemo,
} from "react";
import styles from "./colorSelect.module.scss";
import { IColorSelectCommonProps, defaultProps } from ".";
import { IChildren } from "../FilterSelect/FilterSelectClient";
import { IAddtionProps } from "./GlassColor/GlassColorClient";
import { IStyleProps } from "@/src/app/interface";
import { FmIcon } from "@/ui-component";
export interface IColorSelectClientProps
    extends IColorSelectCommonProps,
        IChildren,
        IStyleProps {
    rowCenter?: boolean;
}
const ColorSelectClient: React.FC<IColorSelectClientProps> = (props) => {
    const {
        rowCenter,
        showSize = defaultProps["showSize"],
        selected,
        onChange,
        popoverClassName,
    } = props;
    const [showMorePanel, setShowMorePanel] = useState<boolean>(false);

    const panelRef = useRef<HTMLDivElement>(null);
    const arrowRef = useRef<HTMLSpanElement>(null);
    const colorContainerRef = useRef<HTMLUListElement>(null);
    const togglePanel = useCallback((showpanel: boolean) => {
        setShowMorePanel(showpanel);
    }, []);

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            // 为false时不用再执行
            if (!showMorePanel) {
                return;
            }
            if (
                panelRef.current &&
                !panelRef.current.contains(event.target as any) &&
                arrowRef.current &&
                arrowRef.current !== event.target
            ) {
                togglePanel(false);
            }
        },
        [showMorePanel, togglePanel],
    );
    useEffect(() => {
        getGlobal().addEventListener("mousedown", handleClickOutside);
        return () => {
            getGlobal().removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside, togglePanel]);

    const handleChange = useCallback(
        (e: React.MouseEvent<HTMLLIElement, MouseEvent>, value: number) => {
            e.stopPropagation();
            onChange?.(value);
        },
        [onChange],
    );

    const scaleStyle = useMemo(() => {
        return {
            "--scale": props.scale || 1,
        } as CSSProperties;
    }, [props.scale]);

    return (
        <div
            className={classnames(
                styles["color-select-container"],
                props.className,
            )}
        >
            <ul
                className={classnames(
                    styles["product-select"],
                    "product-color-select-container",
                    {
                        [styles["row-center"]]: rowCenter,
                    },
                )}
                ref={colorContainerRef}
            >
                {/* 展示showSize的childrenList */}
                {React.Children.map(props.children, (child, index) => {
                    if (index >= showSize) {
                        return;
                    }
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, {
                            selected: selected,
                            onChange: handleChange,
                        } as IAddtionProps);
                    }
                    return child;
                })}
            </ul>
            {React.Children.count(props.children) > showSize && (
                <div
                    className={classnames(
                        styles["hidden-glasses-wrapper"],
                        popoverClassName?.wrapper,
                    )}
                >
                    <FmIcon
                        icon={"icon--arrow-down1"}
                        style={{
                            transform: `rotate(${showMorePanel ? 180 : 0}deg)`,
                            ...scaleStyle,
                        }}
                        className={styles["arrow-down"]}
                        onClick={() => togglePanel(!showMorePanel)}
                        ref={arrowRef}
                    />
                    <div
                        className={classnames(
                            styles["hidden-glasses-container"],
                            {
                                ["hidden"]: !showMorePanel,
                            },
                            popoverClassName?.container,
                        )}
                        style={
                            {
                                "--left": `-${colorContainerRef.current?.clientWidth}px`,
                            } as React.CSSProperties
                        }
                        ref={panelRef}
                    >
                        <ul
                            className={classnames(
                                styles["product-select"],
                                "product-color-select-container",
                            )}
                        >
                            {React.Children.map(
                                props.children,
                                (child, index) => {
                                    if (index < showSize) {
                                        return;
                                    }
                                    if (React.isValidElement(child)) {
                                        return React.cloneElement(child, {
                                            selected: selected,
                                            onChange: handleChange,
                                            scale: props.scale,
                                        } as IAddtionProps);
                                    }
                                    return child;
                                },
                            )}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ColorSelectClient;
