"use client";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import {
    Dialog,
    DialogProps,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogTitleProps,
} from "@mui/material";
import stl from "../styles/fmDialog.module.scss";
import classnames from "classnames";
import FmIcon from "./FmIcon";
import FmButton from "./FmButton";
import { dialogClassName, drawerClassName } from "./FmDrawer";
import $ from "jquery";
export interface IRenderBottom {
    ok: string;
    cancel: string;
    onOk: Function;
    onCancel: Function;
}

export interface IFmDialogProps extends DialogProps {
    showBottomButton?: boolean;
    dialogTitle?: DialogTitleProps["children"];
    closeable?: boolean; // 显示右上角关闭图标（X)
    iconClassName?: string;
    titleClassName?: string;
    contentClassName?: string;
    paperClassName?: string;
    positionBottom?: boolean;
    contentPosition?: "center" | "right" | "left"; // 整体内容居中或者左或者右
    renderBottom?: ReactNode; // 自定义dialogbottom
    bottom?: IRenderBottom | null;
    bottomClassName?: string;
}

const defaultProps = {
    contentPosition: "left",
    closeable: false,
};
const FmDialog = (prop: IFmDialogProps) => {
    const props = useMemo(() => {
        return { ...defaultProps, ...prop };
    }, [prop]);
    const {
        positionBottom,
        contentClassName,
        closeable,
        paperClassName,
        dialogTitle,
        iconClassName,
        contentPosition, // 整体”内容“是否居中、左、右
        showBottomButton,
        renderBottom,
        titleClassName,
        bottomClassName,
        onClose,
        ...rest
    } = useMemo(() => props, [props]);

    const [showBottom, setShowBottom] = useState(
        showBottomButton || !!renderBottom || !!props.bottom || false,
    ); // 有renderBottom也默认显示对应renderBottom

    useEffect(() => {
        setShowBottom(showBottomButton || !!renderBottom || false);
    }, [renderBottom, showBottomButton]);
    const dialogBottom = useMemo(() => {
        return (
            renderBottom ?? (
                <>
                    <FmButton
                        onClick={(e) => {
                            onClose?.(e, "backdropClick");
                            props.bottom?.onCancel?.();
                        }}
                        // variant={"dark"}
                        className={classnames(
                            stl["render-bottom"],
                            "render-bottom",
                        )}
                    >
                        {props.bottom?.cancel}
                    </FmButton>
                    <FmButton
                        onClick={() => props.bottom?.onOk?.()}
                        variant={"text"}
                        className={stl["render-bottom"]}
                    >
                        {props.bottom?.ok}
                    </FmButton>
                </>
            )
        );
    }, [onClose, props.bottom, renderBottom]);

    // 制止弹窗出现时，body仍可滚动
    useEffect(() => {
        props.open ||
        document.getElementsByClassName(dialogClassName).length > 0 ||
        document.getElementsByClassName(drawerClassName).length > 0
            ? $("body").addClass("setOverflowHiddenImportantly") &&
              $("body").removeClass("setOverflowAutoImportantly")
            : $("body").addClass("setOverflowAutoImportantly") &&
              $("body").removeClass("setOverflowHiddenImportantly");
    }, [props.open]);

    return (
        <Dialog
            onClose={onClose}
            // scroll={props.scroll ?? "body"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            classes={{
                paper: classnames(
                    stl["paper-container"],
                    {
                        [dialogClassName]: props.open,
                        [classnames(stl.paper_wrap, "paper-wrap")]:
                            positionBottom,
                    }, // 设置在bottom底部，一般处理高度不太高的底部栏，但是最好使用fmDrawer
                    paperClassName,
                ),
            }}
            {...rest}
        >
            <DialogTitle
                id="scroll-dialog-title"
                classes={{ root: "dialog_root_container_title" }}
                className={classnames(
                    stl.dialog_title,
                    stl[contentPosition],
                    "dialog_title",
                    {
                        [stl.hide_close_btn]: !dialogTitle && !closeable,
                        [stl.crossable]: closeable && !dialogTitle, // 没有title 又需要scrossable的时候
                    },
                    titleClassName,
                )}
            >
                {dialogTitle}
                <FmIcon
                    // color
                    icon={"icon--close"}
                    // @ts-ignore 这个是函数方法对不上，对不上就对不上吧，他有默认值
                    onClick={onClose}
                    className={classnames(
                        stl["header-right-close"],
                        "header-right-close",
                        {
                            [stl.hide_close_btn]: !closeable,
                        },
                        iconClassName,
                    )}
                />
            </DialogTitle>
            <DialogContent
                classes={{
                    root: classnames(
                        stl.content,
                        stl[contentPosition],
                        contentClassName,
                    ),
                }}
                dividers={!Number.isNaN(props.scroll ?? "body")}
            >
                {props.children}
            </DialogContent>
            <DialogActions
                className={classnames(
                    stl["dialog-actions"],
                    stl[contentPosition],
                    {
                        [stl.hide_close_btn]: !showBottom,
                    },
                    "bottom-class-container",
                    bottomClassName,
                )}
            >
                {dialogBottom}
            </DialogActions>
        </Dialog>
    );
};

export default FmDialog;
