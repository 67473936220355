/**
 * 不同的站点显示打折的方式不太一样 抽取一个方法统一处理
 * @param {*} site 站点 'de' 'fr' 'it '...
 * @param {*} num 打折百分比
 * @param {*} t
 * @returns
 */
export default function getDiscountText(
    site: string,
    num: number,
    t: Function,
) {
    if (num < 0) {
        return "";
    }
    if (["de", "fr", "it", "pt", "br"].includes(site)) {
        return `-${num}%`;
    } else {
        return `${num}% ${t("common:OFF")}`;
    }
}
