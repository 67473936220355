import FmSkeleton from "@/ui-component/basic/FmSkeleton";
import React from "react";

const TryOnSkeleton: React.FC = () => {
    const overallWidth = "95%";
    return (
        <div
            style={{
                overflow: "hidden",
                height: "100vh",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
            }}
        >
            <FmSkeleton
                style={{ margin: "0.2rem 0.2rem 0 0.2rem" }}
                variant="rounded"
                width={"1rem"}
                height={"0.4rem"}
            ></FmSkeleton>
            <FmSkeleton
                style={{ margin: "0.2rem 0.2rem 0 0.2rem" }}
                variant="rounded"
                width={overallWidth}
                height={"7.5rem"}
            ></FmSkeleton>

            <div
                style={{
                    margin: "0.2rem 0.2rem 0 0.2rem",
                    width: overallWidth,
                    display: "flex",
                    height: "0.8rem",
                }}
            >
                <FmSkeleton
                    style={{ margin: "0 0.2rem 0 0", flex: 1 }}
                    variant="rounded"
                    height={"100%"}
                ></FmSkeleton>
                <FmSkeleton
                    style={{ margin: "0 0.2rem 0 0", flex: 1 }}
                    variant="rounded"
                    height={"100%"}
                ></FmSkeleton>
                <FmSkeleton
                    style={{ flex: 1 }}
                    variant="rounded"
                    height={"100%"}
                ></FmSkeleton>
            </div>

            <FmSkeleton
                style={{ margin: "0.2rem 0.2rem 0 0.2rem" }}
                variant="rounded"
                width={overallWidth}
                height={"1.8rem"}
            ></FmSkeleton>

            <div
                style={{
                    boxShadow: "0px -0.06rem 0.12rem rgba(0, 0, 0, 0.08)",
                    position: "absolute",
                    bottom: "0",
                    width: "100%",
                    height: "0.8rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <FmSkeleton
                    style={{ margin: "0 0.4rem" }}
                    variant="rounded"
                    width={"30%"}
                    height={"70%"}
                ></FmSkeleton>
                <FmSkeleton
                    style={{ margin: "0 0.4rem 0 0" }}
                    variant="rounded"
                    width={"60%"}
                    height={"70%"}
                ></FmSkeleton>
            </div>
        </div>
    );
};

export default TryOnSkeleton;
