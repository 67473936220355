import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
    ITryonStateAndAction,
    initialState,
    ITryOnProdcut,
} from "./initialState";
import { jointTryOnImg } from "@/lib/commonService";
import acquisition from "@/src/components/LoadAnalysis/analysis/DataAcquisition";
import { IProductInfoAboutDimensionsAndProducts } from "@/src/app/specsubs/[id]/type";

export const useTryonStore = create<ITryonStateAndAction>()(
    devtools(
        (set, get) => ({
            ...initialState,
            InitTryOn: () => {
                set({
                    productImg: "",
                    productColorImg: "",
                });
            },
            setTryonProduct: (tryOnProduct: ITryOnProdcut) => {
                const {
                    products_color_relation = [],
                    products_color_info = [],
                    products_id,
                } = tryOnProduct;

                let _color_list =
                    products_color_info.length > 0
                        ? products_color_info
                        : products_color_relation;
                let color_list = _color_list.filter(
                    (item: any) => item.color_status != 0,
                );
                if (products_id != get().tryOnProduct.products_id) {
                    set({
                        tryOnProduct,
                        colorList: color_list,
                    });
                }
            },
            setTryOnActiveColor: (colorId: number) => {
                let _activeColor = get().colorList.find(
                    (item) => item.color_id == colorId,
                );

                if (!_activeColor) {
                    set({
                        colorId: colorId,
                    });
                    return;
                }

                _activeColor.products_id = get().tryOnProduct.products_id;
                const productImg = jointTryOnImg(
                    get().tryOnProduct.products_id,
                    _activeColor.color_id,
                );
                set({
                    colorId: colorId,
                    activeColor: _activeColor,
                    productColorImg: "",
                    productImg,
                });
            },
            changeTryOnColorPicture: (img: string) => {
                set({
                    productColorImg: img,
                });
            },
            changeTryOnPicture: (img: string) => {
                set({
                    productImg: img,
                });
            },
            changeTryOnLensColor: (lensColor: any) => {
                set({
                    lensColor,
                });
            },
            cacheTryOnPicture: (kvObj: any) => {
                set({
                    cacheList: {
                        ...get().cacheList,
                        [kvObj.key]: kvObj.val,
                    },
                });
            },
            openTryOn: () => {
                set({
                    openTryOnComp: true,
                });
            },
            closeTryOn: () => {
                set({
                    openTryOnComp: false,
                    productColorImg: "",
                    productImg: "",
                });
            },
            clickButtonToOpenTryOn: (
                activeColorId: number,
                productItemData: Partial<IProductInfoAboutDimensionsAndProducts>,
            ) => {
                get().setTryonProduct?.(productItemData);
                get().setTryOnActiveColor(activeColorId);
                get().openTryOn();
                acquisition.tryOn(
                    "tryOn",
                    `${productItemData.products_id}-${activeColorId}`,
                );
            },
        }),
        {
            name: "try-on-store",
        },
    ),
);
