import { IProductInfoAboutDimensionsAndProducts } from "@/src/app/specsubs/[id]/type";

export interface ProductItemData {}
export interface ITryonProps {
    productImg: string;
    productColorImg: string;
    tryOnProduct: any;
    cacheList: any;
    colorList: any[];
    colorId: number;
    lensColor: any;
    activeColor: any;
    openTryOnComp: boolean; // 打开tryOn模块的弹窗
}

export type State = ITryonProps;

export type Action = {
    InitTryOn: () => void;
    setTryonProduct: (tryOnProduct: any) => void;
    setTryOnActiveColor: (colorId: number) => void;
    changeTryOnColorPicture: (img: string) => void;
    changeTryOnPicture: (img: string) => void;
    changeTryOnLensColor: (lensColor: any) => void;
    cacheTryOnPicture: (kvObj: any) => void;
    openTryOn: () => void;
    closeTryOn: () => void;
    clickButtonToOpenTryOn: (
        activeColorId: number,
        productItemData: Partial<IProductInfoAboutDimensionsAndProducts>,
    ) => void;
};

export interface ITryOnProdcut {
    products_id: number;
    products_color_relation?: any[];
    products_color_info?: any[];
}

export type ITryonStateAndAction = State & Action;

export const initialState: State = {
    productImg: "",
    productColorImg: "",
    tryOnProduct: {},
    cacheList: {},
    colorList: [],
    colorId: 0,
    lensColor: {},
    activeColor: {},
    openTryOnComp: false,
};
