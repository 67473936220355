import React, { FC, ReactNode, useMemo } from "react";
import stl from "../styles/fmTag.module.scss";
import classNames from "classnames";
import { IStyleProps } from "../interface";

interface ITagProps extends IStyleProps {
    color?: string;
    backgroundColor?: string;
    children?: ReactNode;
    onClick?: () => void;
}
// 增加颜色预设，打折/新品/热销，可以直接增加预设

const defaultProps = {
    color: "#fff",
    backgroundColor: "transparent",
};

const FmTag: FC<ITagProps> = (prop) => {
    const props = useMemo(() => {
        return { ...defaultProps, ...prop };
    }, [prop]);
    return (
        <div
            className={classNames(stl.tag, props.className, {
                [stl["visibility-hidden"]]: !props.children,
            })}
            style={{
                backgroundColor: props.backgroundColor,
                color: props.color,
                ...props.style,
            }}
            onClick={props.onClick}
        >
            {props.children ?? "1"}
        </div>
    );
};

export default FmTag;
