"use client";
import React, { ReactNode, useMemo } from "react";
import { FmIcon } from "@/ui-component";
import { openChat } from "@/lib/chatOperation";
import stl from "../../styles/components.module.scss";
import { useCommonContext } from "@/src/provider";
import { EIconType } from "@/public/static/interface";
import H5ContactClient from "./H5ContactClient";

interface IProps {
    H5Contact: any;
    children?: ReactNode;
}

const H5Contact: React.FC<IProps> = (props) => {
    const { host } = useCommonContext();
    const {
        is_show_email,
        chat,
        faq,
        tel,
        is_show_faq,
        is_show_chat,
        title,
        is_show_tel,
        email,
    } = props.H5Contact;

    const showIcon = useMemo(() => {
        return [
            {
                show: is_show_faq,
                icon: "icon--FAQ" as EIconType,
                text: faq,
                onClick: () => {
                    location.href = "help.shtml";
                },
            },
            {
                show: is_show_chat,
                icon: "icon--chat" as EIconType,
                text: chat,
                onClick: () => openChat(host),
            },
            {
                show: is_show_email,
                icon: "icon--email" as EIconType,
                text: email,
            },
            {
                show: is_show_tel,
                icon: "icon--nav-tell" as EIconType,
                text: tel,
            },
        ];
    }, [
        chat,
        email,
        faq,
        host,
        is_show_chat,
        is_show_email,
        is_show_faq,
        is_show_tel,
        tel,
    ]);
    return (
        <div className={stl["home-need-help"]}>
            <div className={stl["title"]}>{title}</div>
            {showIcon.map((item) => {
                return (
                    <H5ContactClient
                        show={item.show}
                        key={item.icon}
                        onClick={item.onClick}
                    >
                        <FmIcon
                            icon={item.icon}
                            style={{ fontSize: "0.48rem", color: "#666" }}
                        />
                        <div className={stl["footer-item"]}>{item.text}</div>
                    </H5ContactClient>
                );
            })}
            {props.children}
        </div>
    );
};

export default H5Contact;
