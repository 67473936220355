"use client";
import React, { useMemo } from "react";
import { FmTag } from "@/ui-component";
import stl from "./tag.module.scss";
import { useTranslation } from "@/src/i18n/client";
import { useFindMyFitStore } from "@/store/modules/findMyFit";
interface Props {
    lensWidth: number; // dimensions_lens
    bridgeWidth: number; // dimensions_bridge
}

const MyFitTag: React.FC<Props> = ({ lensWidth, bridgeWidth }) => {
    const { t } = useTranslation("common");
    const frameInfo = useFindMyFitStore((state) => state.frameInfo);
    const tmp = useMemo(
        () => lensWidth * 2 + bridgeWidth,
        [bridgeWidth, lensWidth],
    );
    const left = useMemo(
        () => (frameInfo[0] as number) * 2 + Number(frameInfo[1]) - 3,
        [frameInfo],
    );
    const right = useMemo(
        () => (frameInfo[0] as number) * 2 + Number(frameInfo[1]) + 3,
        [frameInfo],
    );
    const hiddenDom = useMemo(
        () => tmp < left || tmp > right,
        [left, right, tmp],
    );

    return (
        !(hiddenDom || !frameInfo.length) && (
            <FmTag backgroundColor="#479450a6" className={stl["tag"]}>
                {t("common:FindMyFit.MyFit")}
            </FmTag>
        )
    );
};

export default MyFitTag;
