import EvalAddScript from "../EvalAddScript";
import { EmbedDataProps } from "@/src/utils/innerAdds/handleSchemes";

interface InnerAddsContentProps {
    embedData?: EmbedDataProps;
}

// 渲染，执行站内方案数据
export default function InnerAddsContentEmbed(
    props: Readonly<InnerAddsContentProps>,
) {
    const { embedData } = props;
    return (
        <>
            {embedData &&
                Object.values(embedData).map((data: any, index: number) => (
                    <EvalAddScript key={index} htmlData={data} />
                ))}
        </>
    );
}
