import React, { FC } from "react";
import { IColorItem } from "./GlassColor";
import ColorSelectClient, {
    IColorSelectClientProps,
} from "./ColorSelectClient";
import { IChildren } from "../FilterSelect/FilterSelectClient";

interface IProps {
    selected: number;
    index: number;
    item: IColorItem;
}

interface IColorSelectProps {
    listIsColorData?: boolean;
}

export interface IColorSelectCommonProps extends IChildren {
    selected: number;
    showSize?: number; // 单次显示showSize个，其他的都藏起来
    onChange?: (selected: number) => void;
    popoverClassName?: Partial<{
        wrapper: string;
        container: string;
    }>;
    scale?: number; // 缩放比例: 部分场景下glassColor的图标大小是比普通的大的，为了统一管理和功能，在此添加scale, 表示缩放比例，传入的缩放比例会被应用成=>原始尺寸 * 缩放比例
}

export const defaultProps = {
    showSize: 4, // 单次显示4个，其他的都藏起来：如果过来的list.length > showSize, 则分出showSize - 1, 其他的放到另一部分； 否则直接显示
};
export type ColorSelectProps = IColorSelectProps & IColorSelectClientProps;
const ColorSelect: FC<ColorSelectProps> = (props) => {
    return <ColorSelectClient {...props}>{props.children}</ColorSelectClient>;
};

export default ColorSelect;

export { default as GlassColor } from "./GlassColor";
