"use client";
import React, { useState, useEffect, useMemo } from "react";
import { filterTime } from "@/lib/commonService"; // 根据实际情况进行替换
import styles from "./countDown.module.scss";
import { IIconProps } from "@/ui-component";
import classnames from "classnames"; // 使用 CSS Modules
import { useTranslation } from "@/src/i18n/client";

export interface ICountDownProps extends Partial<IIconProps> {
    lastTime: number;
    startTime: number;
    onClose?: Function;
    className?: string;
}

/**
 * 接受3个参数，都是时间戳
 * 1. now: 现在时间
 * 2. lastTime：截至日期
 * 3. startTime: 开始时间
 *
 * @function CountDown
 * @param {Object} props - 组件属性
 * @param {number} props.lastTime - 截至日期时间戳
 * @param {number} props.startTime - 开始时间时间戳
 * @param {Function} props.onClose - 关闭函数
 * @param {React.ReactElement} props.icon - 图标元素
 * @param {string} props.className - 自定义类名
 */
const CountDownClient: React.FC<React.PropsWithChildren<ICountDownProps>> = (
    props,
) => {
    const { lastTime, startTime, icon, onClose, className } = props;
    const [now, setNow] = useState<number>(Math.floor(Date.now() / 1000));
    const [show, setShow] = useState(now < lastTime && startTime < now);
    const { t } = useTranslation("common");
    useEffect(() => {
        if (now > lastTime || startTime > now) {
            return;
        } else {
            const timer = setInterval(() => {
                setNow(now + 1);
                setShow(now + 1 < lastTime && startTime < now);
                if (!(now + 1 < lastTime && startTime < now)) {
                    clearInterval(timer);
                    typeof onClose === "function" && onClose();
                }
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [now, lastTime, startTime, onClose, props]);

    const date = useMemo(() => filterTime(lastTime - now), [lastTime, now]);

    return show ? (
        <div className={classnames(styles["count-down-container"], className)}>
            {props.children}
            {date.day && (
                <i className={styles["day"]}>
                    {date.day} {t("common:days")}
                </i>
            )}
            <i
                className={classnames(styles["realtime"], {
                    [styles["count-down-font"]]: true,
                })}
            >
                {`${date.hour}:${date.min}:${date.second}`}
            </i>
        </div>
    ) : (
        <></>
    );
};

export default CountDownClient;
