"use client";
import React from "react";
import stl from "../../styles/components.module.scss";
import classnames from "classnames";

interface IProps {
    show: boolean;
    onClick?: () => void;
}

const H5ContactClient: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    return (
        <div
            className={classnames("default_layout", stl["icon-flex-start"], {
                ["hidden"]: !props.show,
            })}
            onClick={() => props.onClick?.()}
        >
            {props.children}
        </div>
    );
};

export default H5ContactClient;
