import Cookies from "js-cookie";
import {
    EmbedDataProps,
    GroupScheme,
    mapEmbedArrToEmbedObj,
    mapSchemes,
    mapSchemesToEmbedDisplayObj,
} from "./handleSchemes";
import { JWT_COOKIE_NAME, VISITOR_COOKIE_NAME } from "@/config/constConf";
import { HandlePhpFetch } from "../fetch";
import { SCHEME } from "@/config/api";
import { getAccount } from "@/src/models/account";
import { getVistorId, isLogin } from "@/src/models";
import { getUid } from "@/src/models/personalize";
import acquisition from "@/src/components/LoadAnalysis/analysis/DataAcquisition";

export interface IInnerAddsProps {
    searchParams: Record<string, string | string[]>;
    page_url?: string;
    customerId?: number;
}

export interface InnerAddsDataProps {
    embedData?: EmbedDataProps;
    alertData?: GroupScheme[];
    visitorId?: number;
    pathname?: string;
}

// 客户端获取站内方案
export default async function getInnerAddsSchemes({
    searchParams,
    page_url,
    customerId,
}: IInnerAddsProps): Promise<InnerAddsDataProps | undefined> {
    try {
        let alertData, embedData;
        let visitorId = getVistorId();
        let pathname = page_url;
        if (!pathname?.startsWith("/")) {
            pathname = "/" + pathname;
        }
        const store: any = {
            path: pathname,
            search:
                Object.keys(searchParams).length === 0
                    ? ""
                    : JSON.stringify(searchParams),
            visitorId,
            customerId,
        };
        const schemesData = await HandlePhpFetch.get(
            `/${SCHEME}-new?${new URLSearchParams(store)}`,
        );
        if (schemesData.visitorId && visitorId != schemesData.visitorId) {
            visitorId = schemesData.visitorId;
            Cookies.set(
                VISITOR_COOKIE_NAME,
                JSON.stringify({ visitor_id: visitorId }),
                {
                    expires: 30 * 12,
                },
            );
            if (!isLogin()) {
                const uid = getUid();
                const userId = uid ? "U" + uid : "V" + visitorId;
                acquisition.login("UserId ", userId);
            }
        }
        if (schemesData.schemes && schemesData.schemes?.length > 0) {
            const schemes = schemesData.schemes;
            const schemesClassify = mapSchemes(schemes);
            // 弹出弹窗
            if (schemesClassify.hasAlert) {
                alertData = schemesClassify.alertArr;
            }
            // 展示嵌入
            if (schemesClassify.hasEmbed) {
                const embedDisplayArr = mapSchemesToEmbedDisplayObj(
                    schemesClassify.embedArr,
                );
                const embedDisplayObj = mapEmbedArrToEmbedObj(embedDisplayArr);
                embedData = embedDisplayObj;
            }
        }
        return { embedData, alertData, visitorId, pathname };
    } catch (e) {
        return undefined;
    }
}
