/**
 * 点击button打开tryOn的模块，一般适用于产品item, 如专题页、首页的产品列表的tryOn
 */
import { FmDrawer, IDrawerProps } from "@/ui-component";
import React from "react";
import stl from "./tryOn.module.scss";
import classnames from "classnames";
import dynamic from "next/dynamic";
import TryOnSkeleton from "./TryOnSkeleton";
import { useTryonStore } from "@/store/modules/tryon";

const ImageTryOn = dynamic(() => import("@/src/components/TryOn/ImageTryOn"), {
    ssr: false,
    loading: () => <TryOnSkeleton />,
});

interface IProps extends IDrawerProps {}
const CompTryOn: React.FC<IProps> = (props) => {
    const { onClose } = props;
    const open = useTryonStore((state) => state.openTryOnComp);

    return (
        <FmDrawer
            open={open}
            onClose={onClose}
            closeable={true}
            anchor={"bottom"}
            fullHeight
            closeablePosition={"right"}
            childrenClassName={"try-on-content"}
            className={stl["try-on-container"]}
            headerClassName={classnames(
                stl["try-on-close-icon"],
                "try-on-close-icon",
            )}
            iconClassName={stl["icon-font-size"]}
        >
            <ImageTryOn />
        </FmDrawer>
    );
};

export default CompTryOn;
