/**
 * Created by Sunnov008 on 2017/9/19.
 */
import $ from "jquery";
// const document = document? document: {createElement:e=>null, cookie:''};
// const jQuery = $;
function whichTransitionEvent() {
    //css动画完成
    var el = document.createElement("surface"),
        transitions = {
            transition: "transitionend",
            OTransition: "oTransitionEnd",
            MozTransition: "transitionend",
            WebkitTransition: "webkitTransitionEnd",
        };
    for (var t in transitions) {
        if (el.style[t] !== undefined) {
            return transitions[t];
        }
    }
}

//设置cookie
function setCookie(c_name, value, expiredays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie =
        c_name +
        "=" +
        escape(value) +
        (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
}

//获取cookie
function getCookie(c_name) {
    if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
            c_start = c_start + c_name.length + 1;
            var c_end = document.cookie.indexOf(";", c_start);
            if (c_end == -1) c_end = document.cookie.length;
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
    return "";
}

/**
 * Created by Sunnov008 on 2017/9/19.
 */
export function UnicodeTransverter(string) {
    var s = "";
    for (var i = 0; i < string.length; i++) {
        s += string[i].charCodeAt(0).toString(16);
    }
    return s;
}

var UPAlertUrls = {
    routes: [], //UPAlert集
    initW: false,
    init: function () {
        if (this.initW) {
            return;
        }
        this.initW = true;
        var popstate = function () {
            for (var i = 0; i < UPAlertUrls.routes.length; i++) {
                var route = UPAlertUrls.routes[i];
                if (route.url == window.location.hash.substr(1)) {
                    route.obj.custom(true);
                } else {
                    route.obj.W && route.obj.CloseC();
                }
            }
        };
        window.addEventListener("popstate", popstate, false);
    },
    addPU: function (data) {
        UPAlertUrls.routes.push({
            url: data.config.url,
            obj: data,
        });
    },
};

export default function UPAlert(config) {
    return A.bind({})(config);
    function A(config) {
        //new UPAlert()
        var me = this;
        var transitionEvent = whichTransitionEvent();
        var data = {
            type: "alert", //inlay 嵌入,swiper 滑出swiperLeft,swiperBottom
            html: "", //内容
            url: "UP" + Math.random().toString(36).substr(2), //随机锚点
            alone: false, //是否单独显示,不关闭其他窗口
            condition: {}, //弹出条件   页面关闭时概率弹出close:'true'/'false';  延迟弹出 delay:number; 每天弹出次数 everyday:1; 镶嵌是否可以被关闭 isclose:'true'/'false';页面静止弹出  statics:number;  只弹出一次 user:'true'/'false';
            position: null, //镶嵌位置
            significance: !!config.significance, //重要：significance：true
            zIndex: null,
            btns: [
                {
                    //按钮1//按钮可以自定义
                    label: "OK",
                    // cssClass:"",
                    val: true,
                },
                {
                    //按钮2
                    label: "Cancel",
                    val: false,
                },
            ],
            callback: function (data) {
                me.CloseCustom();
            }, //关闭回调函数
            onsuccessup: null, //窗口弹出事件
        };
        if (!config) {
            return data;
        }

        //config赋值
        if (typeof config == "object") {
            if (
                config[0] instanceof HTMLElement ||
                config instanceof HTMLElement
            ) {
                data.html = config;
                config = data;
            } else {
                for (var i in data) {
                    if (config[i] === undefined) {
                        config[i] = data[i];
                    }
                }
            }
        } else if (typeof config == "string" || typeof config == "number") {
            data.html = config;
            config = data;
        }
        config.html =
            config.html[0] instanceof HTMLElement
                ? config.html[0]
                : config.html instanceof HTMLElement
                  ? config.html
                  : config.html;
        //对应移除事件
        this.config = config;
        var closeW = this.config.type == "alert" ? true : false;
        var $body = $("body");

        //弹出式
        this.messagePopup = function () {
            var $content = "";
            if (this.config.type == "alert") {
                $content = $(
                    '<div id="UP' +
                        UnicodeTransverter(this.config.url) +
                        '" class="UPAlert" style="box-sizing:border-box;overflow:hidden;overflow-y: auto;position:fixed;text-align:center;top:0;left:0;width:100%;height:100%;opacity: 0;font-size:16px;center;z-index: -1;background-color:rgba(0,0,0,0.5);padding:15px;-webkit-transition:all 0.1s linear;transition:all 0.1s linear;-o-transition:all 0.1s linear">' +
                        '<table style="table-layout: fixed;word-wrap:break-word;vertical-align: middle;text-align: center;width:100%;height:100%;"><tr style="width:100%;height:100%;text-align: center;"><td>' +
                        '<div class="' +
                        (this.config.significance &&
                        this.config.significance == true
                            ? ""
                            : "onCloseCustom") +
                        '" style="width:100%;height:100%;position: absolute;top:0;left:0;left0;display: block;opacity: 0;"></div>' +
                        '<div class="messagePopup" style="-moz-box-shadow:0 2px 5px #555; display:inline-block;position:relative;-webkit-box-shadow:0 2px 5px #555; box-shadow:0 2px 5px #555;background:#fff;min-width:290px;max-width:100%;">' +
                        '<div class="messagePopup_p" style="display: block;position: absolute;z-index:0;top:0;left:0;width:100%;height:100%;"><div class="messagePopup_bor_out" style="display: block;width:100%;height:100%;"><div class="messagePopup_b" style="display: block;width:100%;height:100%;"></div></div></div>' +
                        (closeW
                            ? '<div class="onCloseCustom" style="display: block;top:0;line-height: 25px;right:5px;width:30px;height:30px;position:absolute;cursor:pointer;z-index: 20;font-size: 25px;text-align: center;">&times;</div>'
                            : "") +
                        '<div class="UP_config_html" style="text-align: left;position:relative;z-index: 10;"></div>' +
                        "</div>" +
                        "</td></tr></table>" +
                        "</div>",
                );
            } else if (this.config.type == "confirm") {
                var buttons = "";
                for (var b = 0; b < this.config.btns.length; b++) {
                    var btnData = this.config.btns[b];
                    buttons +=
                        '<button data-UPval="' +
                        btnData.val +
                        '" class="' +
                        btnData.cssClass +
                        ' UPbtn">' +
                        btnData.label +
                        "</button>";
                }
                var Style =
                    "<style>" +
                    ".UPbtn {transition: all 0.15s ease;min-width:100px;line-height:34px;border:1px solid #333;background: #fff;color:#333;display: inline-block;vertical-align: middle;margin:10px;}" +
                    ".UPbtn:hover {transition: all 1s ease;background: #333;color:#fff;}" +
                    "</style>";
                $content = $(
                    '<div id="UP' +
                        UnicodeTransverter(this.config.url) +
                        '" class="UPAlert" style="box-sizing:border-box;overflow:hidden;overflow-y: auto;position:fixed;text-align:center;top:0;left:0;width:100%;height:100%;opacity: 0;font-size:16px;center;z-index: -1;background-color:rgba(0,0,0,0.5);padding:15px;-webkit-transition:all 0.1s linear;transition:all 0.1s linear;-o-transition:all 0.1s linear">' +
                        Style +
                        '<table style="table-layout: fixed;word-wrap:break-word;vertical-align: middle;text-align: center;width:100%;height:100%;"><tr style="width:100%;height:100%;text-align: center;"><td>' +
                        '<div class="' +
                        (this.config.significance &&
                        this.config.significance == true
                            ? ""
                            : "onCloseCustom") +
                        '" style="width:100%;height:100%;position: absolute;top:0;left:0;left0;display: block;opacity: 0;"></div>' +
                        '<div class="messagePopup" style="-moz-box-shadow:0 2px 5px #555; display:inline-block;position:relative;-webkit-box-shadow:0 2px 5px #555; box-shadow:0 2px 5px #555;background:#fff;min-width:290px;max-width:100%;border:1px solid #ccc;padding:28px 15px;">' +
                        '<div class="messagePopup_p" style="display: block;position: absolute;z-index:0;top:0;left:0;width:100%;height:100%;"><div class="messagePopup_bor_out" style="display: block;width:100%;height:100%;"><div class="messagePopup_b" style="display: block;width:100%;height:100%;"></div></div></div>' +
                        '<div class="UP_config_html" style="text-align: left;overflow: hidden;position:relative;z-index: 10;"></div>' +
                        '<div class="UP_btns" style="padding-top:40px;text-align: center;position:relative;z-index: 10;">' +
                        buttons +
                        "</div>" +
                        "</div>" +
                        "</td></tr></table>" +
                        "</div>",
                );
            }
            return $content;
        };
        var messagePopupFunc = function () {
            //弹窗
            this.$div
                .css({
                    "z-index": this.config.zIndex ? this.config.zIndex : 998,
                })
                .animate(
                    {
                        opacity: 1,
                    },
                    90,
                );
        };
        var messagePopupFuncClose = function () {
            transitionEvent &&
                this.$div[0].addEventListener(transitionEvent, function (e) {
                    var op = me.$div.css("opacity");
                    if (op !== "0") {
                        return;
                    }
                    me.$div.css("z-index", -1);
                    me.$div.remove();
                    me.divW = false;
                    try {
                        if (window.addEventListener) {
                            this.removeEventListener("transitionEvent", false); //safari, chrome, firefox下，删除事件func
                        } else {
                            // IE
                            this.attachEvent("transitionEvent"); //ie,opera 下使用删除事件func
                        }
                    } catch (w) {}
                });
            this.$div.css({ opacity: 0 });
        };
        var unicodet = UnicodeTransverter(this.config.url);
        this.id = "UP" + unicodet;
        //右侧滑出式
        this.messageSwiper = function () {
            var unicodet = UnicodeTransverter(this.config.url);

            var Style, Div;
            if (
                this.config.type == "swiperLeft" ||
                this.config.type == "swiper"
            ) {
                Style =
                    "<style>" +
                    ".UPBodySwiper" +
                    UnicodeTransverter(this.config.url) +
                    "{height:100%;overflow: visible;margin: 0 320px 0 -320px;position: relative;}" +
                    ".UPTransitionSwiper{transition: all 1s ease;}" +
                    ".UPCloseSwiper{color: rgb(255, 255, 255);background-color: rgb(0, 0, 0);transition-delay: 0s;right: 320px;animation: toggle_UPContentSwiper1 1s;cursor: pointer;font-size: 27px;position: absolute;top: 0;z-index: 2147483647;height: 38px;width: 38px;text-align: center;line-height: 33px;}" +
                    ".UPbackgSwiper" +
                    unicodet +
                    "{transition: all 1s ease;opacity: 0;background-color: rgba(0, 0, 0,0.5);visibility: visible;left: 0;position: absolute;text-align: center;top: 0;width: 100%;height:100%;z-index: 0;animation: toggle_UPContentSwiper2 1s;}" +
                    ".UPContentSwiper" +
                    unicodet +
                    "{background:#fff;z-index:2;border: 0;display: block !important;height: 100% !important;max-height: 100%;overflow: auto;max-width: none !important;position: absolute;right: -320px;top: 0;width: 320px !important;animation: toggle_UPContentSwiper1 1s;}" +
                    ".UPWinSwiper{height:100%;border: 0;right: 0;width: 100%;margin:0 0 0 -320px;position: fixed;top: 0;z-index: 2147483647;overflow: hidden;transition-delay: 0s !important;}" +
                    ".UPBodySwiper" +
                    unicodet +
                    " .UPContentSwiper" +
                    unicodet +
                    "{right: 0;}" +
                    ".UPBodySwiper" +
                    unicodet +
                    " .UPbackgSwiper" +
                    unicodet +
                    "{opacity: 1;}" +
                    "@keyframes toggle_UPContentSwiper1 {0% {right: -320px}100% {right: 0}}" +
                    "@keyframes toggle_UPContentSwiper2 {0% {opacity: 0}100% {opacity: 1}}" +
                    "</style>";
                Div =
                    '<div class="UPWinSwiper" id="UP' +
                    UnicodeTransverter(this.config.url) +
                    '">' +
                    Style +
                    '<div class="UPbackgSwiper' +
                    unicodet +
                    " UPTransitionSwiper " +
                    (this.config.significance &&
                    this.config.significance == true
                        ? ""
                        : "onCloseCustom") +
                    '"><div class="UPCloseSwiper onCloseCustom">&times;</div></div>' +
                    '<div class="UP_config_html UPContentSwiper' +
                    unicodet +
                    ' UPTransitionSwiper" style="text-align: left;overflow: hidden;"></div>' +
                    "</div>";
            } else if (this.config.type == "swiperBottom") {
                Style =
                    "<style>" +
                    ".UPTransitionSwiper{transition: all 0.6s ease;}" +
                    ".UPCloseSwiper{color: #333;background-color: transparent;transition-delay: 0s;cursor: pointer;font-size: 27px;position: absolute;top: -4px;right: -4px;z-index: 2147483647;height: 38px;width: 38px;text-align: center;line-height: 33px;}" +
                    ".UPbackgSwiper" +
                    unicodet +
                    "{opacity: 0;background-color: rgba(0, 0, 0,0.5);visibility: visible;left: 0;position: absolute;text-align: center;top: 0;width: 100%;height:100%;z-index: 0;animation: toggle_UPContentSwiper2 0.6s;}" +
                    ".UPContentSwiper" +
                    unicodet +
                    "{background:#fff;z-index:2;border: 0;display: block !important;height: auto !important;max-height: 100%;overflow: auto;max-width: none !important;position: absolute;left: 0; bottom: -100%;width: 100% !important;animation: toggle_UPContentSwiper1 0.6s;}" +
                    ".UPWinSwiper{height:100%;border: 0;left: 0;width: 100%;margin:0;position: fixed;top: 0;z-index: 2147483647;overflow: hidden;transition-delay: 0s !important;}" +
                    ".UPBodySwiper" +
                    unicodet +
                    " .UPContentSwiper" +
                    unicodet +
                    "{bottom: 0;}" +
                    ".UPBodySwiper" +
                    unicodet +
                    " .UPbackgSwiper" +
                    unicodet +
                    "{opacity: 1;}" +
                    "@keyframes toggle_UPContentSwiper1 {0% {bottom: -100%}100% {bottom: 0}}" +
                    "@keyframes toggle_UPContentSwiper2 {0% {opacity: 0}100% {opacity: 1}}" +
                    "</style>";
                Div =
                    '<div class="UPWinSwiper" id="UP' +
                    UnicodeTransverter(this.config.url) +
                    '">' +
                    Style +
                    '<div class="UPbackgSwiper' +
                    unicodet +
                    " UPTransitionSwiper " +
                    (this.config.significance &&
                    this.config.significance == true
                        ? ""
                        : "onCloseCustom") +
                    '"></div>' +
                    '<div class="UPContentSwiper' +
                    unicodet +
                    ' UPTransitionSwiper"><div class="UPCloseSwiper onCloseCustom">&times;</div>' +
                    '<div class="UP_config_html" style="text-align: left;overflow: hidden;padding-top:20px;"></div>' +
                    "</div>" +
                    "</div>";
            }
            return $(Div);
        };
        var messageSwiperFunc = function () {
            $body
                .addClass("UPTransitionSwiper")
                .addClass("UPBodySwiper" + UnicodeTransverter(this.config.url));
        };
        var messageSwiperFuncClose = function () {
            var $UPbackgSwiper = this.$div.find(
                ".UPbackgSwiper" + UnicodeTransverter(this.config.url),
            );
            var OPA = $UPbackgSwiper.css("opacity");
            if (OPA == 0) {
                me.$div.remove();
                me.divW = false;
                $body
                    .removeClass(
                        "UPBodySwiper" + UnicodeTransverter(this.config.url),
                    )
                    .removeClass("UPTransitionSwiper");
                return;
            }
            transitionEvent &&
                $UPbackgSwiper[0].addEventListener(
                    transitionEvent,
                    function (e) {
                        var op = $UPbackgSwiper.css("opacity");
                        if (op !== "0") {
                            return;
                        }
                        me.$div.remove();
                        me.divW = false;
                        try {
                            if (window.addEventListener) {
                                this.removeEventListener(
                                    "transitionEvent",
                                    false,
                                ); //safari, chrome, firefox下，删除事件func
                            } else {
                                // IE
                                this.attachEvent("transitionEvent"); //ie,opera 下使用删除事件func
                            }
                        } catch (w) {}
                    },
                );
            $body
                .removeClass(
                    "UPBodySwiper" + UnicodeTransverter(this.config.url),
                )
                .removeClass("UPTransitionSwiper");
        };

        //嵌入式
        this.messageImplant = function () {
            var unicodet = UnicodeTransverter(this.config.url);
            var Style =
                "<style>" +
                "#UP" +
                unicodet +
                "{clear: both;position:relative;height:0;width:0;overflow: hidden;opacity: 0;transition:all 0.5s linear}" +
                "#UP" +
                unicodet +
                ".hover{height:auto;width:auto;opacity: 1;}" +
                "#UP" +
                unicodet +
                " .UPCloseImplant{display: block;top:0;line-height: 25px;right:5px;height:25px;position:absolute;cursor:pointer;z-index: 2;font-size: 25px;text-align: center;}" +
                "</style>";
            var Div =
                '<div id="UP' +
                UnicodeTransverter(this.config.url) +
                '" >' +
                Style +
                (this.config.condition.isclose == true
                    ? '<div><div class="UPCloseImplant onCloseCustom"><span style="font-size:12px;">Close </span>&times;</div></div>'
                    : "") +
                '<div class="UP_config_html" style="text-align: left;overflow: hidden;"></div>' +
                "</div>";
            var $content = $(Div);
            $body = $(this.config.position);
            return $content;
        };
        var messageImplantFunc = function () {
            this.$div.addClass("hover");
        };
        var messageImplantFuncClose = function () {
            this.$div.removeClass("hover");
            this.$div.remove();
        };

        if (this.config.type == "alert" || this.config.type == "confirm") {
            this.$div = this.messagePopup();
            this.messagefunc = messagePopupFunc;
            this.messageClosefunc = messagePopupFuncClose;
        } else if (this.config.type == "inlay") {
            this.$div = this.messageImplant();
            this.messagefunc = messageImplantFunc;
            this.messageClosefunc = messageImplantFuncClose;
        } else if (
            this.config.type == "swiper" ||
            this.config.type == "swiperBottom" ||
            this.config.type == "swiperLeft"
        ) {
            this.$div = this.messageSwiper();
            this.messagefunc = messageSwiperFunc;
            this.messageClosefunc = messageSwiperFuncClose;
        }
        this.container = this.$div.find(".UP_config_html");
        this.container.html(this.config.html);
        this.container.find("img").css("max-width", "100%");

        this.custom = function (W) {
            this.W = true;
            $body.append(this.$div);
            const scripts = $(`#${this.id} script`);
            if (scripts.length >= 1) {
                scripts.each(function (index) {
                    try {
                        Function($(this).html())();
                    } catch (e) {
                        console.log(e);
                    }
                });
            }
            this.messagefunc();
            this.$div.on(
                "click",
                "[data-UPval]",
                function (e) {
                    e.stopPropagation();
                    var output = $(e.target).attr("data-UPval");
                    this.config.callback(output, $(e.target), this);
                }.bind(this),
            );
            this.$div.on(
                "click",
                ".onCloseCustom",
                function (e) {
                    e.stopPropagation();
                    this.config.callback();
                }.bind(this),
            );

            //设置cookie
            if (
                this.config.condition &&
                this.config.condition.Cookie &&
                this.config.condition.Cookie[0] &&
                !getCookie(this.config.condition.Cookie[0])
            ) {
                setCookie(
                    this.config.condition.Cookie[0],
                    true,
                    this.config.condition.Cookie[1],
                );
            }
            //返回关闭功能

            if (W || this.config.type == "inlay") {
                typeof this.config.onsuccessup == "function" &&
                    this.config.onsuccessup(this);
                return;
            }

            // var u = navigator.userAgent, app = navigator.appVersion;
            // var iPhone = u.indexOf('iPhone') > -1; //是否为iPhone或者QQHD
            // var ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //io
            // var iPad = u.indexOf('iPad') > -1; //是否i
            // if (W || this.config.type == "inlay" || iPhone || ios || iPad || !this.config.url) {
            //     typeof this.config.onsuccessup == 'function' && this.config.onsuccessup(this);
            //     return;
            // }
            if (!this.config.alone) {
                for (var i = 0; i < UPAlertUrls.routes.length; i++) {
                    //关闭其他弹窗
                    var route = UPAlertUrls.routes[i];
                    if (route.obj == this) {
                        continue;
                    }
                    route.obj.CloseC();
                }
            }
            //去掉浏览器回退关闭弹框
            var MURL = window.location.hash.substr(1); //修改RUL
            if (MURL !== this.config.url) {
                window.location.hash = this.config.url;
                // window.history.replaceState(
                //   MURL,
                //   0,
                //   window.location.pathname +
                //     window.location.search +
                //     '#' +
                //     this.config.url
                // ); //replaceState
                // window.history.pushState(MURL, 0, window.location.pathname + window.location.search + "#" + this.config.url);//replaceState
            }
            typeof this.config.onsuccessup == "function" &&
                this.config.onsuccessup(this);
        }; //开启弹窗

        this.CloseCustom = function () {
            if (
                this.W == true &&
                this.config.type !== "inlay" &&
                (!this.config.condition ||
                    typeof this.config.condition.close == "undefined" ||
                    this.config.condition.close == false)
            ) {
                this.W = false;
                this.CloseC();
                if (document.location.hash.indexOf("#UP") >= 0) {
                    var s = document.location.hash.indexOf("#UP");
                    // history.go(-1);
                }
            } else {
                this.W = false;
                this.CloseC();
            }
        }; //后退

        this.CloseC = function () {
            try {
                this.messageClosefunc();
            } catch (e) {
                console.warn(e);
            }
        }; //关闭弹窗

        if (
            this.config.type !== "inlay" &&
            (!this.config.condition ||
                typeof this.config.condition.close == "undefined" ||
                this.config.condition.close == false)
        ) {
            //关闭 返回开关弹窗功能
            // UPAlertUrls.init();//激活返回开关弹窗功能
            // UPAlertUrls.addPU(this);//添加到返回开关弹窗数据
        }
        for (var condition in this.config.condition) {
            if (this.config.condition[condition]) {
                this.config.condition.W = true;
                break;
            }
        }
        if (this.config.condition.W) {
            //弹出条件
            let me = this;
            let condition = this.config.condition;
            if (condition.Cookie) {
                //存在cookie不弹出
                this.W = getCookie(condition.Cookie[0]);
            }
            if (!this.W) {
                window.timers = [];
                $(function () {
                    if (
                        condition.delay !== false &&
                        typeof parseFloat(condition.delay) == "number" &&
                        condition.delay * 1 >= 0
                    ) {
                        //页面加载完成后 几秒后弹出
                        window.timers.push(
                            setTimeout(
                                me.custom.bind(me),
                                condition.delay * 1000,
                            ),
                        );
                    } else if (
                        condition.everyday !== false &&
                        typeof parseFloat(condition.everyday) == "number" &&
                        condition.everyday * 1 >= 0
                    ) {
                        //每天弹一次
                        window.timers.push(
                            setTimeout(me.custom.bind(me), 1000),
                        );
                    } else if (condition.user && condition.user !== false) {
                        //新用户
                        window.timers.push(
                            setTimeout(me.custom.bind(me), 1000),
                        );
                    }
                    if (condition.close == true || condition.close == "true") {
                        //页面关闭时弹出
                        $("body").on("click", "a,button,input", function () {
                            me.W = true;
                        });
                        var Onbefor = function () {
                            if (Math.random() < 0.3) {
                                window.onbeforeunload = function () {
                                    if (!me.W) {
                                        me.custom();
                                        return "Leaving so soon? there is a bigger discount for you, click stay button to check out.";
                                    }
                                };
                            } else {
                                window.timers.push(setTimeout(Onbefor, 1000));
                            }
                        };
                        Onbefor();
                    }
                    if (
                        condition.statics !== false &&
                        typeof parseFloat(condition.statics) == "number" &&
                        condition.statics * 1 > 0
                    ) {
                        //静止几秒后弹出
                        var timer = null;
                        $("body").on(
                            "click mousemove mouseover mouseout keydown",
                            function () {
                                if (me.W) {
                                    return;
                                }
                                clearTimeout(timer);
                                timer = setTimeout(
                                    me.custom.bind(me),
                                    condition.statics * 1000,
                                );
                                window.timers.push(timer);
                            },
                        );
                        $("body").scroll(function () {
                            if (me.W) {
                                return;
                            }
                            clearTimeout(timer);
                            timer = setTimeout(
                                me.custom.bind(me),
                                condition.statics * 1000,
                            );
                            window.timers.push(timer);
                        });
                    }
                });
            }
        } else {
            this.custom();
        } //弹出条件

        if (this.config.type == "inlay") {
            this.config.html.upalert = this;
            this.$div[0].upalert = this;
            return this;
        }
        this.$div[0].upalert = this;
        return this;
    }
}
