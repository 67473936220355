// 站内通用方法，绑定到全局window下面
import $ from "jquery";
import Display, { UnicodeTransverter } from "./display";
import Router from "next/router";
//Detect user device whether lt ie 8
//评论图片预览

//设置cookie
function setCookie(c_name, value, expiredays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie =
        c_name +
        "=" +
        escape(value) +
        (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
}

//获取cookie
function getCookie(c_name) {
    if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
            c_start = c_start + c_name.length + 1;
            var c_end = document.cookie.indexOf(";", c_start);
            if (c_end == -1) c_end = document.cookie.length;
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
    return "";
}

function whichTransitionEvent() {
    //css动画完成
    var el = document.createElement("surface"),
        transitions = {
            transition: "transitionend",
            OTransition: "oTransitionEnd",
            MozTransition: "transitionend",
            WebkitTransition: "webkitTransitionEnd",
        };
    for (var t in transitions) {
        if (el.style[t] !== undefined) {
            return transitions[t];
        }
    }
}

function getParam(jsname, parm) {
    //获取js后面 的参数

    var js = document.scripts;
    var jsPath = "";
    for (var i = js.length; i > 0; i--) {
        if (js[i - 1].src.indexOf(jsname) > -1) {
            jsPath = js[i - 1].src;
            if (parm) {
                break;
            }
        }
    }
    if (!jsPath || !parm) {
        return jsPath;
    }
    var urlparse = jsPath.split("?");
    var parms = urlparse[1].split("&");
    var values = {};
    for (let i = 0; i < parms.length; i++) {
        var pr = parms[i].split("=");
        if (pr[0] == parm) return pr[1];
    }
    return "";
}

//复制
function Copy(select) {
    var text = $(select).text() || $(select).val();

    var $textarea = $(
        "<p style='opacity:0;top:0;z-index:-999;left:0;position:absolute'>" +
            text +
            "</p>",
    );
    $("body").append($textarea);
    let range = document.createRange();
    range.selectNodeContents($textarea[0]);
    let selection = document.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand("Copy");
    // var explorer = navigator.userAgent;
    // //ie                           //firefox                           //Chrome                          //Opera                           //Netscape
    // if (explorer.indexOf("MSIE") >= 0 || explorer.indexOf("Firefox") >= 0 || explorer.indexOf("Chrome") >= 0 || explorer.indexOf("Opera") >= 0 || explorer.indexOf("Netscape") >= 0) {
    //   $textarea.select();
    //   document.execCommand("Copy");
    // }
    // else if (explorer.indexOf("Safari") >= 0) {//Safari
    //   alert("Your browser doesn't support the \"copy button\" function. Please copy the code directly. ");
    //   return false;
    // }
    // $textarea.remove();
    var $div = $(
        '<div style="position:fixed;bottom:30%;left:0;width:100%;text-align: center;z-index: 999999"><div style="border-radius: 5px;color:#fff;background:rgba(0,0,0,0.8);padding:5px 10px;display: inline-block;">Copied</div></div>',
    );
    $("body").append($div);
    var timer = setTimeout(function () {
        $div.animate(
            {
                opacity: 0,
            },
            500,
            function () {
                $div.remove();
                $textarea.remove();
                clearTimeout(timer);
            },
        );
    }, 1500);
    return true;
}

function page_clone() {
    var sg = $(".sendEmail_getCode");
    if (sg.length <= 0) {
        return;
    }
    var $it1 = $($('.sendEmail_getCode input[type="email"]:visible')[0]);
    var $bt1 = $($('.sendEmail_getCode button[type="submit"]:visible')[0]);
    var bt2 = null;
    var it2 = null;
    var h = 31;
    if ($it1 && $bt1) {
        it2 = $it1
            .clone()
            .removeAttr("id")
            .removeAttr("class")
            .attr("required", true);
        bt2 = $bt1.clone().removeAttr("class");
    } else {
        return;
    }
    var turnForm = document.createElement("form");
    $(it2).css({
        height: h + "px",
        width: "71%",
        border: "1px solid #323232",
        "box-sizing": "border-box",
        "line-height": h - 2 + "px",
        padding: "0 8px",
        "text-align": "left",
        display: "tableCell",
        "vertical-align": "middle",
    });
    $(bt2).css({
        height: h + "px",
        border: "1px solid #323232",
        width: "29%",
        "box-sizing": "border-box",
        "line-height": h - 2 + "px",
        padding: "0 15px",
        "background-color": "#323232",
        color: "#fff",
        display: "tableCell",
        "vertical-align": "middle",
    });
    $(turnForm)
        .append(it2, bt2)
        .addClass("footer_bar row hidden-lg hidden-md col-sm-12 col-xs-12")
        .css({
            "text-align": "right",
            padding: "10px",
            boxSizing: "border-box",
            margin: 0,
            display: "table",
            whiteSpace: "nowrap",
        });

    var PH = -52;
    $(turnForm).css("bottom", PH);
    $(".main-container").append(turnForm);
    $it1.keyup(function () {
        $(it2).val($it1.val());
    });
    $(it2).keyup(function () {
        $it1.val($(it2).val());
    });
    $(turnForm).submit(function () {
        $bt1.click();
        return false;
    });

    var Switch = PH;
    var sgt = sg.offset().top + 300;
    $(window).scroll(function () {
        var s = $(window).scrollTop();
        if (s > sgt) {
            if (Switch === 0) {
                return;
            } else {
                Switch = 0;
            }
        } else {
            if (Switch === PH) {
                return;
            } else {
                Switch = PH;
            }
        }
        turnForm_Animate(Switch);
    });

    var handleRouteChange = (url) => {
        $(turnForm).remove();
        Router.events.off("routeChangeStart", handleRouteChange);
    };

    Router.events.on("routeChangeStart", handleRouteChange);

    function turnForm_Animate(bm) {
        $(turnForm).stop();
        $(turnForm).animate(
            {
                bottom: bm,
            },
            400,
        );
    }
}
function GetRequest() {
    var url = location.search; //获取url中"?"符后的字串
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
    }
    return theRequest;
} //获取?后面的参数

function loginFacebook_clone() {
    var sg = $(".sendEmail_getCode");
    if (sg.length <= 0) {
        return;
    }
    var a = $("#loginFacebook");
    var a2 = null;
    var h = 31;
    if (a.length > 0) {
        a2 = a.clone().removeAttr("id").css({
            "line-height": "29px",
        });
    } else {
        return;
    }
    var turnForm = document.createElement("div");
    $(turnForm)
        .append(a2)
        .addClass("footer_bar row hidden-lg hidden-md col-sm-12 col-xs-12")
        .css({
            "text-align": "right",
            padding: "10px",
            height: "51px",
            margin: 0,
        });

    var PH = -52;
    $(turnForm).css("bottom", PH);
    $("body").append(turnForm);

    var Switch = PH;
    var sgt = sg.offset().top + 300;
    $(window).scroll(function () {
        var s = $(window).scrollTop();
        if (s > sgt) {
            if (Switch === 0) {
                return;
            } else {
                Switch = 0;
            }
        } else {
            if (Switch === PH) {
                return;
            } else {
                Switch = PH;
            }
        }
        turnForm_Animate(Switch);
    });

    function turnForm_Animate(bm) {
        $(turnForm).stop();
        $(turnForm).animate(
            {
                bottom: bm,
            },
            400,
        );
    }
}
//按钮克隆功能2
function bindWindow(func) {
    for (let i in func) {
        if (!window[i]) {
            window[i] = func[i];
        } else {
            null;
        }
    }
}

export default function bindFunctionToWindow() {
    var transitionEvent = whichTransitionEvent();
    bindWindow({
        $,
        getCookie,
        setCookie,
        whichTransitionEvent,
        transitionEvent,
        Copy,
        page_clone,
        GetRequest,
        loginFacebook_clone,
        UnicodeTransverter,
        UPAlert: Display,
    });
    window.$.fn.scrollshow = function (top) {
        var T = top ? top : 50;
        this.each(function () {
            $(this).show();
            $("html,body").animate(
                {
                    scrollTop: $(this).offset().top - T,
                },
                500,
            );
        });
    };
}
