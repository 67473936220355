"use client";
import React, { useCallback, useEffect, useMemo } from "react";
import Script from "next/script";
import { sanitize } from "isomorphic-dompurify";
import useMounted from "../hooks/useMounted";

type EvalAddScriptProps = {
    htmlData: any;
};

const EvalAddScript: React.FC<EvalAddScriptProps> = ({ htmlData }) => {
    // 匹配 script 标签
    const scriptRegex = useMemo(
        () => /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
        [],
    );
    // 匹配 link 标签
    const linkRegex = useMemo(() => /<link[^>]*href="([^"]+)"[^>]*>/gi, []);
    const mounted = useMounted();
    const loadLinkStyles = useCallback(
        (htmlString: string) => {
            const linkMatches = htmlString.match(linkRegex);
            if (linkMatches) {
                linkMatches.forEach((linkMatch) => {
                    // 处理 link 引入样式表
                    const hrefMatch = linkMatch.match(/href="([^"]+)"/i);
                    if (hrefMatch && hrefMatch[1]) {
                        const href = hrefMatch[1];
                        // 创建 link 元素
                        const linkElement = document.createElement("link");
                        linkElement.rel = "stylesheet";
                        linkElement.type = "text/css";
                        linkElement.href = href;
                        // 将 link 元素添加到 head 中
                        document.head.appendChild(linkElement);
                    }
                });
            }
        },
        [linkRegex],
    );

    const separateScript = useCallback(
        (htmlString: string) => {
            loadLinkStyles(htmlString);
            const scriptTags = htmlString?.match(scriptRegex);
            if (scriptTags) {
                return scriptTags
                    .filter((tag) => {
                        // 处理 script 引入脚本
                        const scriptSrcMatch = tag.match(
                            /<script\b[^<]*src="([^"]+)"[^<]*><\/script>/gi,
                        );
                        if (scriptSrcMatch) {
                            const srcMatch = tag.match(/src="([^"]+)"/i);
                            if (srcMatch && srcMatch[1]) {
                                const src = srcMatch[1];
                                // 创建 script 元素
                                const scriptElement =
                                    document.createElement("script");
                                scriptElement.src = src;
                                scriptElement.async = false;
                                // 将 script 元素添加到 head 中
                                document.head.appendChild(scriptElement);
                            }
                            return false;
                        } else {
                            return true;
                        }
                    })
                    .map((scriptTag) => {
                        return scriptTag.replace(/<\/?script[^>]*>/gi, "");
                    })
                    .join(";");
            } else return "";
        },
        [loadLinkStyles, scriptRegex],
    );

    const loadScript = useMemo(() => {
        if (!mounted) return;
        let script = "";
        if (Array.isArray(htmlData?.html)) {
            script = htmlData?.html
                .map((item: any) => separateScript(item?.html))
                .join(";");
        } else {
            script = separateScript(htmlData?.html);
        }
        return script;
    }, [htmlData?.html, mounted, separateScript]);
    function processImages(htmlString: string) {
        // 使用正则表达式匹配<img>标签
        const imgTagPattern = /<img\s+([^>]*?)>/gi;

        // 替换函数，用于在每个匹配到的<img>标签中插入loading属性并修改src后缀
        function replaceImgTag(match: string, attrs: string) {
            // 确保<img>标签中有src属性
            if (attrs.includes("src=")) {
                // 添加loading="lazy"
                let newAttrs = attrs.replace(/(src=\S+)/i, `$1 loading="lazy"`);

                return `<img ${newAttrs}>`;
            } else {
                // 如果没有src属性，直接返回原标签
                return match;
            }
        }

        // 执行替换操作
        const styleRegex = /<style>[\s\S]*?<\/style>/;
        // 兼容style标签在第一层级跟div同级的情况
        const styleHtml = htmlString
            .replace(imgTagPattern, replaceImgTag)
            .replace(styleRegex, (match) => {
                // 用 div 标签包裹 style 标签
                return `<div>${match}</div>`;
            })
            .replace(/Roboto-Regular/g, "var(--font-montserrat)")
            .replace(/Roboto-Medium/g, "var(--font_montserrat-medium)")
            .replace(/Roboto-Bold/g, "var(--font_montserrat-bold)")
            .replace(/Roboto-Light/g, "var(--font_montserrat-light)");

        return styleHtml;
    }
    return (
        <>
            {loadScript && (
                <Script strategy={"lazyOnload"} id={htmlData?.id}>
                    {`${loadScript}`}
                </Script>
            )}
            {Array.isArray(htmlData) ? (
                htmlData.map((item, index) => (
                    <div
                        key={"htmlData-" + index}
                        dangerouslySetInnerHTML={{
                            __html: sanitize(processImages(item)),
                        }}
                    />
                ))
            ) : htmlData?.html ? (
                <div
                    dangerouslySetInnerHTML={{
                        __html: sanitize(processImages(htmlData?.html)),
                    }}
                />
            ) : null}
        </>
    );
};

export default EvalAddScript;
